.home {
	position: relative;
	height: 100%;

	&__body {
		height: 100%;
		width: 100%;
		display: flex;
	}

	&__map {
		width: 100%;
		height: 100%;
	}
}
