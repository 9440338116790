.layout-picker-mobile {
	display: none;
}

.layout-picker {
	display: flex;
	position: absolute;
	bottom: 25px;
	left: 50%;
	transform: translateX(-50%);

	&__info {
		color: #fff;
		background-color: #333;
		margin: 0 4px;
		border-radius: 4px;
		opacity: 0.9;
		padding: 10px 32px;
		white-space: nowrap;
		font-weight: bold;
		cursor: pointer;
		border: 3px solid transparent;
		display: flex;
		align-items: center;

		svg {
			margin-right: 8px;
		}

		&.active {
			background-color: #be2a2a;
			border-color: #fff;
		}
	}
}

@media screen and (max-width: 765px) {
	.layout-picker {
		display: none;
	}

	.layout-picker-mobile {
		display: block;
		position: absolute;
		top: 20px;
		right: 20px;
		// width: 188px;
		height: 56px;
		border-radius: 50px;
		background-color: #fff;
		z-index: 10;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		font-size: 16px;
		font-weight: bold;
		padding-right: 10px;
		padding-left: 40px;

		.layout-picker-icon {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 43px;
			height: 43px;
			border-radius: 50%;
			background-color: #e9f0f4;
			margin-left: 10px;

			svg {
				color: #5e8fac;
			}
		}
	}
}
