@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

* {
	box-sizing: border-box;
}

html,
body,
#root {
	height: 100%;
}

h1,
p {
	margin: 0;
	padding: 0;
}

body {
	margin: 0;
	font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#windy {
	width: 100%;
	height: 100vh;
	position: absolute !important;
	top: 0;
}

#windy #logo-wrapper {
	display: none;
}
