.header {
	position: absolute;
	top: 30px;
	left: 49px;
	min-width: 246px;
	height: 80px;
	border-radius: 50px;
	background-color: #fff;
	z-index: 1000;
	color: #000;
	display: flex;
	align-items: center;
	padding: 9px 15px 9px 9px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

	&__icon {
		width: 62px;
		height: 62px;
		background-color: #cfcfcf;
		border-radius: 50%;
		margin-right: 11px;
	}

	&__name h1 {
		font-size: 30px;
		line-height: 32px;
	}

	&__name p {
		font-size: 14px;
	}
}

@media screen and (max-width: 765px) {
	.header {
		display: none;
		// top: 15px;
		// left: 20px;
		// width: 172px;
		// min-width: auto;
		// height: 56px;

		// &__icon {
		// 	width: 43px;
		// 	height: 43px;
		// 	margin-right: 8px;
		// }

		// &__name h1 {
		// 	font-size: 22px;
		// 	line-height: 26px;
		// }

		// &__name p {
		// 	font-size: 10px;
		// }
	}
}
