.sidebar {
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 1000;
	height: 100%;
	opacity: 0.95;
	padding-top: 10px;

	&__block {
		max-width: 410px;
		width: 410px;
		margin-top: 10px;
		margin-right: 20px;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 12px 10px;
		border-bottom: 1px solid #ededed;
	}

	&__name {
		display: flex;
		align-items: center;
		font-size: 18px;
		font-weight: bold;

		img {
			margin-right: 10px;
		}
	}

	&__info {
		font-size: 14px;
		color: #333;
	}

	&__body {
		display: flex;
		width: 100%;
	}

	&__split {
		width: 100%;
		text-align: center;
		padding: 30px 0;
		margin: 10px 0;

		&:first-of-type {
			border-right: 1px solid #ededed;
		}
	}

	&__split-label {
		font-size: 14px;
		color: #ababab;
		text-transform: uppercase;
		margin-bottom: 8px;
	}

	&__split-value {
		font-size: 16px;
		color: #333;
		font-weight: bold;
	}

	&__image {
		width: 100%;
		height: 140px;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}

@media screen and (max-width: 765px) {
	.sidebar {
		width: 100%;
		height: auto;
		bottom: auto;

		bottom: 0;

		max-height: 150px;
		opacity: 0.8;
		opacity: 1;
		padding-top: 15px;
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
		background-color: #fff;

		transition: max-height 0.2s ease-in-out;

		&.open {
			max-height: 80%;
		}

		&__block {
			width: 90%;
			margin-left: auto;
			margin-right: auto;
			margin-top: 0;
			padding-bottom: 10px;
		}

		&__reset {
			display: none;
		}

		&__split {
			padding: 10px 0;
		}
	}
}
