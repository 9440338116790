.compass {
	position: fixed;
	left: 20px;
	bottom: 20px;
	z-index: 10000;
	width: 139px;
	height: 158px;

	&__main {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	&__needle {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}

@media screen and (max-width: 765px) {
	.compass {
		display: none;
		// width: 97px;
		// height: 110px;
		// left: auto;
		// bottom: auto;
		// top: 10px;
		// right: 10px;
	}
}
