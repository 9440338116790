.leaflet-container {
	width: 100%;
	height: 100%;
}

.leaflet-control-layers,
.leaflet-control-container {
	display: none;
}

.input {
	width: 100%;
	padding: 10px 8px;
	font-size: 20px;
}

.App {
	height: 100%;
}

.home {
	height: 100%;
	overflow: hidden;
}
