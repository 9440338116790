.debug-options {
	position: fixed;
	width: 450px;

	min-height: 300px;
	height: auto;
	bottom: 30px;
	left: 30px;
	background-color: #fff;
	z-index: 1000;
	padding: 15px;
	box-shadow: 0 10px 15px rgba(0,0,0,0.1);
	border-radius: 5px;

	&__field {
		margin-bottom: 10px;
	}

	&__field-label {
		font-size: 20px;
		color: #3e3e3e;
		font-weight: bold;
		margin-bottom: 10px;
	}

    &__field-value{
        font-size: 20px;
    }
}

@media screen and (max-width: 765px) {
	.debug-options{
		display: none;
	}
}
